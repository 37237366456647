body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-ld {
  color: #ffffff!important;
  border-width: 0px!important;
  border-color: rgba(0,0,0,0);
  border-radius: 100px;
  font-size: 24px;
  font-family: 'Barlow',Helvetica,Arial,Lucida,sans-serif!important;
  font-weight: 900!important;
  font-style: italic!important;
  background-image: linear-gradient(154deg,#FFA60D 0%,#FC7C10 100%);
  padding: 15px 30px !important;
  transition: padding 300ms ease 0ms;
  line-height: 1.7em!important;
  background-color: transparent;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  margin-top: 30px;
}